import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router";
import { Helmet } from "react-helmet";
import Layout1 from "./Layout1";
import ShareLinks from "./ShareLinks";
import Seo from "./Seo"; // Import the Seo component
import '../assets/scss/components/SectionSpecial.scss'
import NewsletterSubscription from "./NewsletterSubscription";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { AlltranslationsBannerFirst, AlltranslationsBannerSecond } from "../templates/banner";
import TelegramComponent from "./telegram";

function groupStringsByAlphabet(stringsArray) {
  const groupedStrings = {};

  stringsArray.forEach((str) => {
    const firstLetter = str.title[0].toUpperCase();

    if (!groupedStrings[firstLetter]) {
      groupedStrings[firstLetter] = [str];
    } else {
      groupedStrings[firstLetter].push(str);
    }
  });

  return groupedStrings;
}

function connectString(string) {
  const arr = string.toLowerCase().split(" ");
  return arr.join("-");
}

const AllReciters = () => {
  const data = useStaticQuery(graphql`
    query {
      allAuthorTranslationsJson {
        nodes {
          title
          description
          image {
            id
          }
        }
      }
      site {
        siteMetadata {
          title
          description
          siteUrl
          featuredPostCount
          postPerPage
        }
      }
    }
  `);
  const { t, i18n } = useTranslation();

  const sorted = data.allAuthorTranslationsJson.nodes.slice().sort((a, b) => a.title.localeCompare(b.title));
  const data1 = groupStringsByAlphabet(sorted);
  const keysArray = Object.keys(data1);

  const sorted2 = data.allAuthorTranslationsJson.nodes.slice().sort((a, b) => a.title.localeCompare(b.title));
  const data12 = groupStringsByAlphabet(sorted2);
  const keysArray2 = Object.keys(data12);

  const location = useLocation();
  console.log(data1, "------------------")
  const SectionSpecial = () => {
    return (
      <div className="SectionSpecial-cont">
        <h2>Quran Translations MP3</h2>
        <div>
          {keysArray2.map((item, index) => (
            <div key={index}>
              {data12[item].map((item, index) => (
                <Link hrefLang={i18n.language} to={`/translations/${connectString(item.title)}/`} key={index}>
                  {item.title}
                </Link>
              ))}
            </div>

          ))}
        </div>

      </div>
    );
  };
  return (
    <Layout1>
      <Helmet>
        {/* Add hrefLang attribute in the head */}
        <link rel="alternate" hrefLang="en" href="https://www.quranplayermp3.com/all-translations/" />
        <link rel="alternate" hreflang="ar" href="https://www.quranplayermp3.com/ar/all-translations/" />
        <link rel="alternate" hreflang="fr" href="https://www.quranplayermp3.com/fr/all-translations/" />
        <link rel="alternate" hrefLang="es" href="https://www.quranplayermp3.com/es/all-translations/" />
        <link rel="alternate" hreflang="de" href="https://www.quranplayermp3.com/de/all-translations/" />
        <link rel="alternate" hreflang="ur" href="https://www.quranplayermp3.com/ur/all-translations/" />
        <link rel="alternate" hrefLang="zh" href="https://www.quranplayermp3.com/zh/all-translations/" />
        <link rel="alternate" hreflang="ru" href="https://www.quranplayermp3.com/ru/all-translations/" />
        <link rel="alternate" hreflang="tr" href="https://www.quranplayermp3.com/tr/all-translations/" />
        <link rel="alternate" hreflang="pt" href="https://www.quranplayermp3.com/pt/all-translations/" />
        <link rel="alternate" hreflang="it" href="https://www.quranplayermp3.com/it/all-translations/" />
        <link rel="alternate" hreflang="id" href="https://www.quranplayermp3.com/id/all-translations/" />
        <link rel="alternate" hreflang="bn" href="https://www.quranplayermp3.com/bn/all-translations/" />
        <link rel="alternate" hreflang="nl" href="https://www.quranplayermp3.com/nl/all-translations/" />
        <link rel="alternate" hreflang="hi" href="https://www.quranplayermp3.com/hi/all-translations/" />
        <link rel="alternate" hreflang="fa" href="https://www.quranplayermp3.com/fa/all-translations/" />
        <link rel="alternate" hreflang="ug" href="https://www.quranplayermp3.com/ug/all-translations/" />
        <link rel="alternate" hreflang="so" href="https://www.quranplayermp3.com/so/all-translations/" />
        <link rel="alternate" hreflang="sw" href="https://www.quranplayermp3.com/sw/all-translations/" />
        <link rel="alternate" hreflang="no" href="https://www.quranplayermp3.com/no/all-translations/" />
        <link rel="alternate" hreflang="da" href="https://www.quranplayermp3.com/da/all-translations/" />
        <link rel="alternate" hreflang="se" href="https://www.quranplayermp3.com/se/all-translations/" />
        <link rel="alternate" hreflang="ha" href="https://www.quranplayermp3.com/ha/all-translations/" />
        <link rel="alternate" hreflang="bs" href="https://www.quranplayermp3.com/bs/all-translations/" />
        <link rel="alternate" hreflang="th" href="https://www.quranplayermp3.com/th/all-translations/" />
        <link rel="alternate" hreflang="tg" href="https://www.quranplayermp3.com/tg/all-translations/" />
        <link rel="alternate" hreflang="tl" href="https://www.quranplayermp3.com/tl/all-translations/" />
        <link rel="alternate" hreflang="x-default" href="https://www.quranplayermp3.com/all-translations/" />
      </Helmet>
      <Seo
        isAllTranslationPage={true} // Pass the isAllRecitersPage prop as true
        description={t("Embark on a spiritual journey with renowned Quran translations. Experience the soul-stirring recitations in multiple languages with MP3 downloads of the Holy Quran. Listen and connect with the divine words.")}
        title={t("All Quran Translations - Download Languages in Quran MP3")}
      />
      <div className="main" style={{ minHeight: "calc(100vh - 80px)", display: "grid", placeItems: "center" }}> {/* Use CSS Grid for centering */}
        <h1 className="recirtersTitle post-title">{t("Quran MP3 Translations in 37 Languages")}</h1>
        {keysArray.map((item, index) => (
          <div className="container mx-auto max-w-screen-xl" key={index}>
            <div
              className={`w-full sm:w-1/2 md:w-1/3 lg:w-1/5 xl:w-1/5 p-2 flex ${index === 0 || index === 1 ? 'flex-col' : 'flex-row'}`}
            >
              {/* Mostrar Banner solo en dispositivos móviles */}
              {index === 0 ? (
                <div className="first-banner-show">
                  <AlltranslationsBannerFirst />
                </div>
              ) : ''}
              {index === 1 ? (
                <div className="first-banner-show">
                  <AlltranslationsBannerSecond />
                </div>
              ) : ''}
              <div className="all_card">
                <h2 className="all_card-title">{t(item)}</h2>
              </div>

              {/* Mostrar Banner solo en computadoras */}
              {index === 0 ? (
                <div className="second-banner-show">
                  <AlltranslationsBannerFirst />
                </div>
              ) : ''}
              {index === 1 ? (
                <div className="second-banner-show">
                  <AlltranslationsBannerSecond />
                </div>
              ) : ''}
            </div>
            <div className="flex flex-wrap justify-center">
              {data1[item].map((item, index) => (
                <Link hrefLang={i18n.language} to={`/translations/${connectString(item.title)}/`} className="max-w-100 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 px-4 mb-4" key={index}>
                  <div className="card">
                    <h2 className="card-title">{t(item.title)}</h2>
                  </div>
                </Link>
              ))}
            </div>
            {/*index === 6 && <SectionSpecial />*/}
          </div>

        ))}
      </div>
      <ShareLinks url={location.href} title={t("All Quran Translations - Download Languages in Quran MP3")} />
      <TelegramComponent />
      <NewsletterSubscription />
    </Layout1>
  );
};

export default AllReciters;
